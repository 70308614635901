.bg-black{
    background: rgb(9,138,168);
    /* background: linear-gradient(90deg, rgba(9,138,168,1) 25%, rgba(61,176,204,1) 75%); */

    /* background-color: #18181c; */
}
.text-white {
    color:white
}
.navbar-btn{
    margin: 5px;
    font-family: "Titan One", sans-serif;
    font-weight: 200;
    font-style: normal;
}
.grow-small { 
    transition: all .2s ease-in-out; 
}
    
.grow-small:hover { 
    transform: scale(1.05); 
}
.navbar{
    min-height: 5vh;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
 }
 .nav-route{
    margin-left:10px;
    margin-right:10px
 }
  