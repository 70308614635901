.text-align-center {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-blue{
  color:#61dafb !important;
  -webkit-text-stroke-width: .25px;
  -webkit-text-stroke-color: white;

}
.main-background{
	/* background: rgb(54,140,189);
	background: linear-gradient(90deg, rgba(54,140,189,1) 25%, rgba(149,0,254,1) 75%); */
  background: #13c2ec;
  min-height: 95vh;
}

.vertical-center{
  display: flex;
  align-items: center;
}
.background-blue-95vh{
  background-color: #13c2ec;
  min-height:95vh;
}
.full-screen-button{
  
color:rgba(149,0,254,1);
}
.full-screen-button:hover{
  cursor: pointer;
}
.mute-button{
  color:rgba(149,0,254,1);

}
.mute-button:hover{
  cursor: pointer;
}
.controls-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligns items to the right */
  padding-top: 10px;
  padding-right: 10px;

}

.controls-container p {
  margin: 0 10px;
  cursor: pointer;
}

.controls-container input[type="range"] {
  margin-left: 10px;
}
/* styles.css */
.volume-slider {
  height: 8px; /* Adjust height as needed */
  accent-color: rgba(149,0,254,1);
  border-radius: 5px; /* Round corners of the track */
}

@media only screen and (max-width: 767px) {
  .tilt-on-mobile {
    transform: rotate(90deg);
    transform-origin: center;
    width: 100vh; /* Adjust dimensions as necessary */
    height: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(90deg) translate(-50%, -50%);
  }
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~game set up settings~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.setup-image{
  border-radius: 5px;
  display: block; /* Ensure the image behaves as a block element */
  margin: 0 auto; /* Center horizontally */
  max-width: 80%;

}
.check-button{
  font-size: 2rem;
  border-color: #00000040;
  border:solid 2px #00000040;
  background-color: #fff;
  color: #00000040;
  border-radius: 5px;
  padding:3px;
}

.check-button:hover{
  cursor: pointer;
}
.is-checked{
  background-color: rgba(149,0,254,1);
  border:solid 2px rgba(149,0,254,1);
  color:white;
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~kooblo games css - modal!~~~~~~~~~~~~~~~~~~~~~~~~ */
.jeopardy-modal {
  height:0px;
  width:0px;
  position: absolute;
  background-color: rgb(9,138,168);
  transition: 0.25s ease-in-out;
  border-radius: 10%;
  color:white;
  /* left:50%;
  top:50%;
  transform: translate(-50%,-50%); */

}
.jeopardy-modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  height:80%;
  width:80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}
.jeopardy-modal-container{
  display:block;
  height:100vh;
  width:100vw;
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

}
.jeopardy-modal-expand{
  height:70%;
  width:70%;
  left:50% !important;
  top:50% !important;
  transform: translate(-50%,-50%); 

}

.player-card-buzzed{
  box-shadow: 0 0 30px 25px #ff1493;
}

.jeopardy-button-container {
  position: fixed;
  left: 0;
  bottom: 5%;
  width: 100%;
  text-align: center;

}
.show-answer-btn{
  background: rgba(149,0,254,1);
  color: #fff;  
  border-radius: 40px; 
}
.j-timer-container{
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  text-align: center;

}
.z-3{
  z-index: 3;
}
.game-modal-image{
  height:20vh;
  max-width: 60vw;
}
.game-modal-text{
  font-size: 5vh;
}