/* App.css */
.sidebar {
    background-color: rgba(149,0,254,1);
    color: white;
    height: 93vh;
    border-radius: 25px;
    margin: 1vh;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
    position: -webkit-sticky; 
    position: sticky; 
    top: 10px; 

}
.sidebar .nav-link {
    border-radius: 5px;
    color: white;
}
.sidebar .nav-link:hover {
    background-color: rgba(165, 113, 202, 0.8);
    font-weight: bold;

}
.sidebar .dropdown-menu {
    background-color: rgba(149,0,254,1);
}
.sidebar .dropdown-item {
    color: white;
}
.sidebar .dropdown-item:hover {
    background-color: rgba(165, 113, 202, 0.8);
}
.sidebar span {
    display: block;
    width: 100%;
    text-align: center;
}
.sidebar-subitems{
    margin-left:20px;
}
.sidebar-header{
    font-family: "Titan One", sans-serif;
    font-weight: 100;
    font-style: normal;
    font-size: 1.5rem;
    white-space: nowrap
}
.sidebar-text{
    font-size: 1rem;
    white-space: nowrap;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nav-link.active {
    background-color: rgba(165, 113, 202, 0.8) !important;
    font-weight: bold; /* Optional: Make text bold */
}

.link-style{
    background: 'none';
    border: 'none';
    color: 'blue';
    text-decoration: 'underline';
    cursor: 'pointer';
    padding: 0;
    font: 'inherit';

}
.main-content {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    min-height: 93vh;
    /* Add other styles as needed */
  }
.small-icon {
    width: 16px;
    height: 16px;
    font-size: 12.5px;
}
@media (max-width: 575px) {
    .dropdown {
        position: sticky;
        top: 10px;
        z-index: 1000;
        padding:10px;
    }

    .sidebar {
        display: none; /* Hide the original sidebar menu */
    }
}
