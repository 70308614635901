
.gamecard-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensures the image covers the specified area without distortion */
}
.gamecard-image-body{
    width: 100%;
    height: 150px; /* Set a fixed height for the image */
}
.gametype-header{
    color:white;
    text-align: center;
    font-size: 1rem;
    background-color: blue;
    margin:0px;
}


.card-title {
    font-weight: bold;
}
.grow { 
    transition: all .2s ease-in-out; 
}
    
.grow:hover { 
    transform: scale(1.1); 
}
.jeopardy-border{
    border:4px solid rgb(0, 254, 157);
}
.kooblo-border{
    border:4px solid rgba(149,0,254,1);
}
.kooblo-party-border{
    border:4px solid rgb(255, 233, 31);

}
.jeopardy-tag{
    background:rgb(0, 254, 157);
    border-radius:25%;
    position:absolute;
    top:0;
    left:5px;
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.kooblo-tag{
    background:rgba(149,0,254,1);
    border-radius:25%;
    position:absolute;
    top:0;
    left:5px;
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.kooblo-party-tag{
    background:rgb(255, 233, 31);
    border-radius:25%;
    position:absolute;
    top:0;
    left:5px;
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.edit-set-icon {
    cursor: pointer; /* Show pointer cursor on hover */
    transition: color 0.3s ease; /* Smooth transition for color change */
  }
  
  .edit-set-icon:hover {
    color: rgba(149,0,254,1); /* Change color to rgba(149,0,254,1) on hover */
  }
.save-button:hover{
    background-color: rgb(28, 160, 57) !important;
    color:white
}
.delete-button:hover{
    background-color: rgb(153, 26, 26) !important;
    color:white
}