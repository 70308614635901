
.add-class-card {
    background-color: rgb(214, 217, 223);
    transition: background-color 0.3s, color 0.3s;
}

.add-class-card:hover {
    background-color: rgba(149, 0, 254, 1);
    color: white;
    cursor: pointer;
}

.add-class-card .class-card-title {
    font-weight: bold;
}

/* Ensure icons and text inside card title inherit the hover color */
.add-class-card:hover .card-title,
.add-class-card:hover .card-title i {
    color: white;
}
