.game-preview-img {
    width: 100%; /* Adjust as needed */
    max-height: 150px;
    object-fit: cover;
    border-radius: 10px;
}
@media (max-width: 576px) {
   .h1-responsive {
       font-size: 1.8rem; /* smaller font for small screens */
   }
}
