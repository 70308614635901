.students-container{
    min-height: 80vh;
}
.icon-red{
    color:white;
    background-color: rgb(221, 100, 86);
    padding:5px;
    border-radius: 5px;
    margin-right: 8px; /* Adjust as needed */
}
.icon-red:hover{
    cursor: pointer;
    color:black;
}
.student-name-plate{
    display: flex;
    justify-content: center;
    align-items: center;  
    width:10rem;
    border: 2px solid;
    border-radius: 5px;
    padding: 2px;
    margin:2px;
}
.student-name {
    flex-grow: 1;
    text-align: center;
}
.col-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Ensure items stack vertically */
}
