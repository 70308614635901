@media only screen and (max-width: 767px) {
    .tilt-on-mobile {
      transform: rotate(90deg);
      transform-origin: center;
      width: 100vh; /* Adjust dimensions as necessary */
      height: 100vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: rotate(90deg) translate(-50%, -50%);
    }
  }
  