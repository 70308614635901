.search-input-group {
    position: relative;
    border-radius: 25px;
    width: 100%;
    overflow: hidden; /* Ensure rounded corners apply to the whole input group */
}

.search-input {
    border-radius: 25px !important; /* Ensure border-radius is applied */
    padding-right: 50px !important; /* Adjust padding to ensure space for the thicker border and icon */
    border: 1px solid #ccc; /* Default border color */
    z-index: 1 !important;
    transition: border-color 0.3s ease, border-width 0.3s ease; /* Smooth transition for both color and width */
}

.search-input:focus {
    border-color: rgba(149,0,254,1) !important;
    outline: none; /* Remove default focus outline */
    border-width: 3px; /* Adjust the thickness of the border */

}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #999;
    z-index: 2 !important; /* Ensure the icon is above the input text */
}
.search-icon:hover{
    color:rgba(149,0,254,1);
}
.search-check-button {
    font-size: 1rem;
    border-color: #00000040;
    border: solid 2px #00000040;
    background-color: #fff;
    color: #00000040;
    border-radius: 5px;
    padding: 3px;
    margin-right: 10px;
    margin-left: 10px;

}

.search-check-button:hover{
    cursor: pointer;
    border: solid 2px rgba(149,0,254,1);
}
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-button {
    background-color: rgb(47, 49, 72);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    color:white;
    
}

.pagination-button:disabled {
    background: rgb(78, 81, 114);
    border:3px solid rgba(149,0,254,1);
    
}

.pagination-button:not(:disabled):hover {
    background-color: #ccc;
}

.pagination-button {
    border-radius: 50%;
}

