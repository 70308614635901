body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  #mildlyinfuriatingbird {
    width: 100%;
    height: 100%;
  }
