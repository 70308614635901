.game-banner{
    min-height: 250px;
    background-color: rgb(149, 0, 254);
    align-items: center;
    justify-content: center;
}
.game-logo{
    display: block;
    transition: transform 0.3s ease; /* Smooth transition for the hover effect */
    cursor: pointer;
    max-height: 220px;
    max-width: 100%;
    width: auto; /* Let the width be determined by the image */
    border-radius: 5px;
    align-self: center;
    justify-self: center;
}
.game-logo.selected {
    box-shadow: 0 0 20px 8px rgb(149, 0, 254);
    top:-10px;
}
.game-image {
    max-height: 220px;
    width: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure the image doesn't overflow horizontally */
    object-fit: cover; /* Ensure the image covers the height without stretching */
    display: block; /* Remove any inline padding or margin */
    margin: 0 auto; /* Center the image horizontally if it's smaller */
}
  