.ribbon {
    color: white;
    font-size: 1.2rem;
    padding: 5px 10px;
    font-family: 'Titan One', cursive; /* Adjust the font if necessary */
    border-radius: 5px;
}
.ribbon-position{
    z-index: 1;
    transform: translate(-20%, -20%); /* Adjust the offset to move the ribbon closer */

}
.avatar-container {
    position: relative;
    display: inline-block;
}

#first-place {
    font-size: 2.5rem; /* Larger font size for #1 */
    padding: 7px 12px; /* Adjust padding for a larger ribbon */
    transform: translate(-20%, -20%); /* Adjust to bring closer to avatar */
}

#second-place, #third-place {
    font-size: 1.5rem; /* Smaller font size for #2 and #3 */
    padding: 5px 10px; /* Adjust padding for smaller ribbons */
    transform: translate(-15%, -15%); /* Adjust to bring closer to avatar */
}
