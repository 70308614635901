/* BuzzerButton.css */
.game-id-input{
    width: 50%; /* Adjust the width as needed */
    margin: 10px auto; /* Center the input field */
  }
  .buzzer-team-select{
    border:#02e1ba 1px
  }
  
  .team-select-image {
  height: 150px; /* Set a fixed height */
    width: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers the area without distortion */
}

.team-select-container {
    display: flex; /* Use Flexbox */
    flex-wrap: wrap; /* Allow items to wrap */
    justify-content: center; /* Center items */
}

.team-select-item {
    display: flex; /* Use Flexbox to align items */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
    margin: 10px; /* Add spacing between items */
    height: 100%; /* Ensure all items have the same height */
    flex-grow: 1; /* Allow items to grow to fill space */
}

.team-select-image:hover {
    transform: scale(0.9); /* Scale down on hover */
}
  
  .buzzer-team-select:hover{
    cursor: pointer;
  }
.buzzer-background{
    height: 100vh;
    background-color: #13c2ec;
}
.buzzer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }

  .buzzer-button {
    background-color: rgb(80, 7, 132); /* Your button color */
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 5px;
    width:25%;
    height:10%;

  }
  .buzzer-button-front {
    height:100%;
    padding: 12px 42px;
    border-radius: 12px;
    font-size: 1.25rem;
    background-color: rgba(149, 0, 254, 1); /* Your button color */
    color: white;
    transform: translateY(-15px) translateX(-2px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .buzzer-button-front:hover{
    transform: translateY(-20px) translateX(-4px);

  }
  @media (max-width: 768px) {
    .buzzer-button {
      width:80%;
      height:20%;
    }
  
  }

  .buzzer-button:active .buzzer-button-front {
    transform: translateY(-2px);
  }


