.hero-section{
    min-height: 840px;
    position: relative;
    color:white;
    background: rgb(61,176,204);
    /* background: linear-gradient(180deg, rgba(61,176,204,1) 50%, rgba(255,255,255,1) 100%); */
}
.hero-section-h1{
    text-shadow: 2px 2px 8px rgb(128, 128, 128);
}
.hero-content{
    padding:20px;
}
.hero-secion-img{
    max-width: 100%;
    border-radius: 20px;
}
.inter-font{
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:"slnt" 0;
  }
  
.features-section {
    position: relative;
    background: rgba(149,0,254,1); /* Deep purple background */
    color: #ffffff; /* White text for contrast */
    padding: 60px 0;
}

.features-section h3 {
    font-size: 2.5rem; /* Larger heading for emphasis */
    font-weight: bold;
    margin-bottom: 20px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); /* Subtle shadow for clarity */
}

.features-section p {
    font-size: 1.2rem; /* Scaled for readability */
    line-height: 1.6; /* Improved readability */
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.9); /* Slightly softened white for readability */
}

.feature-image {
    border-radius: 15px;
    width: 100%; /* Optional: Ensures images take full width of their container */
    height: auto; /* Optional: Maintains aspect ratio */
}
.feature-col{
    padding:20px;
}
@media (max-width: 768px) {
    .features-section h3 {
        font-size: 2rem; /* Smaller heading size for mobile */
    }

    .features-section p {
        font-size: 1rem; /* Smaller paragraph size for mobile */
    }
}
/* Add this to your CSS file */

@keyframes slideInLeft {
    0% { transform: translateX(-100%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
  }
  
  @keyframes slideInRight {
    0% { transform: translateX(100%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
  }
  
  .slide-in-left {
    animation: slideInLeft 1s forwards;
  }
  
  .slide-in-right {
    animation: slideInRight 1s forwards;
  }
  
  /* Optional: Initial state for animation */
  .hidden {
    opacity: 0;
  }

  #howitworks {
    padding: 50px 0; /* Space around the section */
  }
  
  .how-it-works-step {
    margin-bottom: 30px; /* Space below each step */
  }
  
  .step-title {
    font-size: 1.5rem; /* Font size for titles */
    font-weight: bold; /* Bold titles */
    margin-bottom: 15px; /* Space below title */
  }
  
  .step-image {
    max-width: 100%; /* Ensure image is responsive */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 15px; /* Space below image */
  }
  
  .step-description {
    font-size: 1rem; /* Font size for descriptions */
    color: #333; /* Text color */
  }
  
.how-it-works-section{
  position: relative;
  padding: 60px 0;
  background: white;
}
.step-image {
  width: 600px;
  height: 400px;
  border-radius: 5px;
}

.subscriptions-section{
  background-color: rgb(149, 0, 254);
}

/* PURPLE DIVIDER */
.custom-shape-divider-top-1726411357 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1726411357 svg {
  position: relative;
  display: block;
  width: calc(131% + 1.3px);
  height: 149px;
}

.custom-shape-divider-top-1726411357 .shape-fill {
  fill: #9500FE;
}
.custom-shape-divider-bottom-1726413970 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1726413970 svg {
  position: relative;
  display: block;
  width: calc(131% + 1.3px);
  height: 149px;
}

.custom-shape-divider-bottom-1726413970 .shape-fill {
  fill: #9500FE;
}
/* end */


  .custom-shape-divider-bottom-1718299330 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1718299330 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 130px;
}

.custom-shape-divider-bottom-1718299330 .shape-fill {
    fill: rgb(61,176,204);
}

.custom-shape-divider-top-1718301091 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1718301091 svg {
    position: relative;
    display: block;
    width: 100%; /* Adjusted for potential overflow issues */
    height: 38px; /* Ensure this height is sufficient for visibility */
}

.custom-shape-divider-top-1718301091 .shape-fill {
    fill: rgb(61,176,204); /* Ensure this color provides good contrast */
}
.custom-shape-divider-top-1722067353 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1722067353 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 81px;
}

.custom-shape-divider-top-1722067353 .shape-fill {
    fill: #3DB0CC;
}
/* Ensure the parent container takes full height */
.banner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9500FE; /* Background color of the wrapper */
  padding: 0 10px; /* Horizontal padding to prevent overflow */
}

/* Center the banner within the wrapper */
.static-banner {
  display: flex;
  overflow-x: auto; /* Allows horizontal scrolling if needed */
  background-color: #9500FE; /* Background color of the banner */
  align-items: center; /* Center-aligns the images vertically */
  padding: 10px; /* Adds padding around the banner */
  white-space: nowrap; /* Prevents line breaks */
}

.image-container {
  display: inline-block;
  margin-right: 10px; /* Space between images */
}

.banner-image {
  width: 250px;
  height: 250px;
  object-fit: cover; /* Ensures images cover the container area */
  border-radius: 5px; /* Rounded corners for images */
}
