.class-card-title {
    font-weight: bold;
}
.class-card-container {
    margin: 15px;
    height: 86px; /* Ensure the card height is consistent */
    display: flex;
    justify-content: center;

}
.class-link-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
    border-top: 1px solid #ccc;
}
.class-card-custom-link {
    flex: 1;
    text-align: center;
    padding: 0.25rem;
    text-decoration: none;
    color: #060b11;
    border-right: 1px solid #ccc;
    margin:0px;
}
.class-card-custom-link:last-child {
    border-right: none;
}
.class-card-custom-link:hover {
    background-color: rgba(149, 0, 254, 1);
    color: white;
    cursor: pointer;
    font-weight: bold;
}
.class-card-custom-link-delete:hover{
    background-color: rgb(167, 60, 60) !important;
}
.class-add-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}
.class-add-card-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.class-add-card-form .class-form-group {
    margin-bottom: 1rem;
}
