.kp-questions-image-preview{
    object-fit: cover;
    max-height: 200px;
}
.wrapped-text {
    flex-grow: 1;
    text-align: center;
    margin-left: 10px; /* Adjust the space between the strong element and the span */
    word-wrap: break-word; /* Breaks long words to fit container */
    overflow-wrap: break-word; /* Ensures compatibility with different browsers */
    white-space: normal; /* Allows text to wrap naturally */
  }
  