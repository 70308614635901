@media (max-width: 576px) {
    .modal-dialog {
      max-width: 95vw;
      margin: 0.5rem auto; /* Center horizontally and add top/bottom margin */
    }
    .modal-body {
      padding: 1rem; /* Ensure padding for small screens */
    }
  }
.is-checked{
  background-color: rgba(149,0,254,1);
  border:solid 2px rgba(149,0,254,1);
  color:white;
}
  