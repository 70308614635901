.kq-link-group {
    /* position: absolute;
    bottom: 0; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensure vertical alignment */
    margin: 0;
    padding: 0;
    margin-top:1px;
    border-top: 1px solid #ccc;
    background-color: white; /* Add a background to prevent overlapping content from showing through */
}
.kq-card-custom-link{
    flex: 1 1;
    text-align: center;
    padding: 0.25rem;
    text-decoration: none;
    color: #060b11;
    border-left: 1px solid #ccc;
    margin: 0px;
}
/* .kq-card-custom-link:last-child {
    border-right: none;
} */
.kq-card-custom-link:hover {
    color: white;
    cursor: pointer;
    font-weight: bold;
}

  .no-resize {
    resize: none;
  }

  /* .question-card {
    display: flex;
    height: 100%;
} */

.image-col {
    display: flex;
    align-items: center;
}

.card-img-zoom {
    /* width: 100%;
    height: 100%; */
    max-height: 240px;
    min-height: 240px;
    object-fit: cover;
    transition: transform 0.3s ease;
}
.min-height-200{
    min-height: 200px;
}
/* .kq-question-card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

/* .kq-question-card .card-body .d-flex {
    position: absolute;
    bottom: 10px;
} */
/* Media Queries for Responsive Card Sizes */
@media (min-width: 1200px) {
    .kq-question-card {
        height: auto;

    }
    .kq-col-4 {
        padding-right: 0px !important;
    }
    .kq-col-8 {
        padding-left: 0px !important;
    }

}

@media (min-width: 768px) and (max-width: 1199px) {
    .kq-question-card {
        height: auto;

    }
    .kq-col-4 {
        padding-right: 0px !important;
    }
    .kq-col-8 {
        padding-left: 0px !important;
    }

}

@media (max-width: 767px) {
    .kq-question-card {
        width: 100%;
        height: auto;
    }
    .kq-card-custom-link:first-child {
        border-left: none;
    }


}
