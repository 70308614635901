@keyframes dots {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
    100% {
      content: "";
    }
  }
  
  .loading-dots::after {
    content: ".";
    animation: dots 1.5s steps(1, end) infinite;
  }
  