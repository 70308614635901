.game-wrapper {
    position: relative;
    display: inline-block;
    transition: transform 0.3s ease;
  }
  
  .game-wrapper:hover {
    transform: translateY(-10px);
  }
  
  .plus-banner {
    position: absolute;
    top: 8px; /* Adjust if needed */
    left: 8px;
    background-color: orange;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    z-index: 10;
    pointer-events: none;
  }

  .kooblo-plus-btn {
    background-color: #ff8c00; /* Orange */
    color: white;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    transition: transform 0.2s, background-color 0.2s;
}

.kooblo-plus-btn:hover {
    background-color: #e07b00; /* Darker orange */
    transform: scale(1.05);
    
}
