.gameboard{
    position: relative;
    width: 80%;
    height:80%;
    justify-content: center;
    flex-direction: column;
    background:red;
}
.save-notification {
    position: absolute;
    top: 0;
    color:#00DE3B;
    z-index: 1000; /* To keep it above other content */
    opacity: 1;
    transition: opacity 1s ease-out; /* Fading effect */
    left: 50%;
    transform: translateX(-50%);  
  }
  
  .save-notification.hidden {
    opacity: 0;
  }
  
.preview-qa{
    padding: 3px !important;
    overflow: hidden;            /* Hides the overflowing text */
    text-overflow: ellipsis;     /* Adds the ellipsis (...) */
    align-content:normal !important;

}
.preview-qa-text{
    font-size: 15px;
    
}
.preview-qa-header{
    font-size:18px;
    border-bottom: #FFFFFF solid;
}
.game-background{
    background: #0d4250;
	min-height: 95vh;
}
.grid-container{
    display: grid;
    padding: 10px;
    height: 90vh;
    margin-right: 5vw;
    margin-left:5vh;
}
.grid-container-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-container-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-container-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-container-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-container-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-category{
    color:white;
    padding: 5px;
    margin:10px;
    font-size: 1.25vw;
    text-align: center;
    align-content: center;
    border-radius: 30px;
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.grid-item {
    color:white;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    margin:10px;
    border-radius: 10px;
    font-size: 30px;
    text-align: center;
    align-content: center;
    font-family: "Nunito", sans-serif; 
    font-weight: 400;
    font-style: normal;

}
.jeopardy-col-1{
    background-color: #B947FF;

}
.jeopardy-col-2{
    background-color:#06CF5D;
}
.jeopardy-col-3{
    background-color:#FF7B00;
}
.jeopardy-col-4{
    background-color:#FF675C;
}
.jeopardy-col-5{
    background-color:#00BFFF;
}
.jeopardy-col-6{
    background-color:#FF66D9;
}
@media screen and (max-width: 1700px) {
    div.grid-category{
        padding: 2px;
        margin:1px;    
    }
    div.grid-item{
        padding: 2px;
        margin:1px;    
    }
    div.grid-container{
        padding: 2px;
        margin:1px;    

    }
}
  