.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vW;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top */
  }
  
  .quiz-modal-dialog {
    height:90vh;
    width: 90vw; /* Adjust as needed */
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .quiz-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .height-half {
    height:50%;
    align-items: center;
    justify-content: center;
  }
    
  .quiz-card-image {
    max-height: 80%;
    max-width: 100%;
    object-fit: contain; /* Ensure the image fits within the container */
    width: auto;
    height: auto;
  
  }
      
  
  .button-size {
    font-size: 16px;
    height:100%;
    width: 100%;
  }
  
/* Blue Button Hover */
.kp-question-btn.btn-light-blue:hover {
  color:white;
  background-color: #044977;
  border-color: #044977;
}

/* Red Button Hover */
.kp-question-btn.btn-light-red:hover {
  color:white;
  background-color: #5e2731;
  border-color: #5e2731;
}

/* Yellow Button Hover */
.kp-question-btn.btn-light-yellow:hover {
  color:white;
  background-color: #5a5220;
  border-color: #5a5220;
}

/* Green Button Hover */
.kp-question-btn.btn-light-green:hover {
  color:white;
  background-color: #025317;
  border-color: #025317;
}
/* Ensure that the image and question sections take up 50% height on small screens */
.mh-50 {
  max-height: 50%;
}
.question-modal-image,
.question-modal-question {
  height: 80%;
}
.invisible-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Fully transparent */
  z-index: 100; /* Ensure it's on top of everything */
  
}
.no-hover {
  cursor: default; /* No pointer cursor */
}

.no-hover:hover {
  background-color: inherit; /* Prevent hover background color change */
  color: inherit; /* Keep text color unchanged */
  box-shadow: none; /* Remove hover box-shadow */
  border-color: inherit; /* Prevent border color change on hover */
}

.no-hover:focus {
  outline: none; /* Remove focus outline */
}

/* Adjust height when columns stack vertically on extra small screens */
@media (max-width: 1320px) {
  .question-modal-image,
  .question-modal-question {
    height: 50% !important;
  }
}
/* Prevent collapsing into a single column on landscape mobile */
/* Handle portrait mode: single-column layout */
@media screen and (max-width: 768px) and (orientation: portrait) {
  .kp-question-btn {
    width: 100%;  /* Ensure each button takes the full width in portrait mode */
  }
}

/* Handle landscape mode: 2x2 grid layout */
@media screen and (max-width: 768px) and (orientation: landscape) {
  .kp-question-btn {
    width: 100%;  /* Buttons take the full width within their column */
  }

  .quiz-modal-content .col-6, .quiz-modal-content .col-sm-6 {
    flex: 0 0 50%;  /* Enforce two columns in landscape mode */
  }
}
@media only screen and (orientation: portrait) {
  .question-modal-image, .question-modal-question {
    flex: 0 0 100%; /* Each column takes 100% width */
    max-width: 100%;
  }
}

/* Ensure 2-column layout in landscape mode */
@media only screen and (orientation: landscape) {
  .question-modal-image, .question-modal-question {
    flex: 0 0 50%; /* Each column takes 50% width */
    max-width: 50%;
  }
}
