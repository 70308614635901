.answer-area{
    color:white;
    border-radius: 5px;
    height:80px;
    position: relative;
    display: flex;
    align-items: center; /* Center input vertically */
    justify-content: center; /* Center input horizontally */
}
.answer-area1{
    background-color: #0291F0;
    border-color: #0291F0;
}
.answer-area2{
    background-color: #DE5F76;
    border-color: #DE5F76;

}
.answer-area3{
    background-color: #DECA49;
    border-color: #DECA49;

}
.answer-area4{
    background-color: #00DE3B;
    border-color:#00DE3B;
}
.kp-questions-image{
    object-fit: cover;
    height: 300px;
}
.answer-area textarea {
    background: transparent; /* Make the input background transparent */
    border: none; /* Remove the border */
    resize: none; /* Prevent resizing */
    color: #000; /* Text color (adjust as needed) */
    font-size: 14px; /* Adjust font size as needed */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
    box-shadow: none; /* Remove box-shadow */
    display: flex;
    overflow-wrap: break-word; /* Ensure long words or URLs break and wrap */
    word-wrap: break-word; /* Fallback for older browsers */
    white-space: pre-wrap; /* Preserve whitespace and wrap text */
    text-align: center; /* Center text horizontally */
    display: flex;
    align-items: center; /* Center text vertically within the textarea */
    line-height: 1.5; /* Adjust line-height to center text vertically */
    width:100%;
    min-height: 10px; /* Minimum height */
    max-height: 100%; /* Maximum height */

}
.kp-answer-container{
    height: 80%;
    width:80%;
    display: flex;
    align-items: center; /* Center input vertically */
    justify-content: center; /* Center input horizontally */
}
.text-area-container{
    height: 80%;
    width:80%;
    display: flex;
    align-items: center; /* Center input vertically */
    justify-content: center; /* Center input horizontally */
}
.text-area-container:hover{
    border: solid 2px black;
    border-radius: 5px;
    cursor:text;
}
.answer-area textarea:focus{
    border: none; /* Hide the border on focus */
    outline: none; /* Remove default outline */
}

/* Styles for the checkbox container */
.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border: 2px solid rgba(149,0,254,1); /* Purple border */
    border-radius: 3px;
    background-color: #fff; /* White background */
    cursor: pointer;
    position: relative;
  }
    
  /* Styles for the checkmark icon */
  .checkbox-container i {
    display: none;
    color: rgba(149,0,254,1); /* Purple checkmark color */
    font-size: 16px; /* Adjust size as needed */
  }
  
  /* Show checkmark icon when checkbox is checked */
  .checkbox-container.checked i {
    display: block;
  }
  
  /* Optionally, add hover effect */
  .checkbox-container:hover {
    background-color: #f0f0f0; /* Light gray background on hover */
  }
  