.masonry-grid {
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Create columns with a min width of 200px */
   gap: 16px; /* Space between items */
}

.masonry-item {
   break-inside: avoid; /* Prevent breaking inside columns */
   overflow: hidden; /* Ensures clean edges */
}

.dropzone{
    min-height: 240px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin:15px;
    background-color:azure;
    border-radius: 25px;
    border-style: dashed;
 }
 .preview-image{
   max-height: 240px;
   min-height: 240px;
   margin:15px;
   max-width: 100%;
 }
 .remove-preview-image{
   position: absolute;
   top: 0;
   left: 0;
   opacity: 30%;
   background-color: red;
   color: white;
   font-size: 16px;
   padding: 10px 20px;
   border: none;
   cursor: pointer;
   border-radius: 5px;

 }
 .remove-preview-image:hover{
   color:black;
 }

.questionset{
   min-height: 240px;
    margin:15px;
}
 .template-card{
    border-radius: 15px;
    margin: 15px;
 }
 .template-image{
   max-width: 100%;
   height:200px;
   border-radius: 15px;
   transition: 1s;
 }
 
 .template-card:hover { 
   top: -10px;
   cursor: pointer;
   }
.template-selected{
   box-shadow: 0 0 10px 10px rgba(149,0,254,1) !important;
   top: -10px;
}
.modal-button{
   
   display: flex;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -ms-flex-align: center;
   align-items: center;
   -ms-flex-pack: end;
   justify-content: flex-end;
   padding: .75rem;
   border-bottom-right-radius: calc(.3rem - 1px);
   border-bottom-left-radius: calc(.3rem - 1px);
}
.pixaImage:hover{
   position:relative;
   top:-10px !important;
   cursor: pointer;
}
.vw-80{
   width: 80vw;
}