.subscription-manager {
    padding-top: 40px;
    padding-bottom: 40px;
}

.subscription-manager h2 {
    margin-bottom: 30px;
}

.subscription-manager .card {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.subscription-manager .card h3 {
    margin-bottom: 20px;
    font-size: 24px;
}

.subscription-manager .card p {
    margin-bottom: 20px;
    color: #555;
}

.subscription-manager .btn {
    font-size: 16px;
    padding: 10px 20px;
}

.free-plan {
    border: 3px solid gray;
}

.monthly-plan {
    border: 3px solid purple;
}

.yearly-plan {
    border: 3px solid gold;
}
