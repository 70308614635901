.basketball-game-container {
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */

    background-color: black; /* Optional: Background color for the container */
    overflow: hidden; /* Prevent scrollbars if needed */
  }
  
  #basketball-game {
    width: 100%;
    height: 100%;
    max-height: 100%; /* Ensure it doesn't exceed container's height */
  }
  canvas{
    overflow: hidden;
  }
  .basketball-game-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  
  #basketball-game {
    width: 100%;
    height: 100%;
  }
  .basketball-game-container {
    touch-action: none; /* Prevent default touch actions */
  }
  