html{
  height:100%
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.p-top-16{
  padding-top:25px;
}
.titan-one-regular {
  font-family: "Titan One", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.titan-one-small {
  font-family: "Titan One", sans-serif;
  font-weight: 100;
  font-style: normal;
}
.nunito {
  font-family: "Nunito", sans-serif; 
  font-optical-sizing: auto;
  font-weight:400;
  font-style: normal;
}
Legacy browser support for variable fonts

.btn-rounded {
  border-radius: 35px;
}
.btn-green {
  background: var(--color-fill-primary-default, #009650);
  color: #fff;
}
.btn-light-green {
  background: var(--color-fill-primary-default, #009650);
  color: #fff;
}
.btn-light-yellow {
  color:white;
  background-color: #ecd647;
  border-color: #dfcb4f;
}

.nav-btn-light-blue {
  background: var(--color-fill-primary-default, #26bbff);
  color: #fff;
}
.btn-light-blue {
  background: var(--color-fill-primary-default, #26bbff);
  color: #fff;
}

.nav-btn-light-blue:hover {
  background: var(--color-fill-primary-default, #177099);
  color: #fff;
}

.nav-btn-light-red {
  background: var(--color-fill-primary-default, #f02e4e);
  color: #fff;
}
.btn-light-red {
  background: var(--color-fill-primary-default, #f02e4e);
  color: #fff;
}

.nav-btn-light-purple {
  background: rgba(149,0,254,1);
  color: #fff;
}

.nav-btn-light-purple:hover{
  background: rgb(90, 1, 153);
  color: #fff;
}
.btn-light-purple {
  background: rgba(149,0,254,1);
  color: #fff;
}
.btn-light-purple:hover{
  background: rgb(71, 1, 121);
  color: #fff;

}
.btn-red {
  background: rgb(190, 34, 34);
  color: #fff;
}
.btn-red:hover{
  background: rgb(99, 18, 18);
  color: #fff;

}
.nav-btn-light-orange {
  background: rgb(211, 119, 34);
  color: #fff;
}

.nav-btn-light-orange:hover{
  background: rgb(114, 65, 19);
  color: #fff;
}

.height-100vh{
    height: 100vh;
}
.waiting-room {
  background: -moz-linear-gradient(45deg, #02e1ba 0%, #26c9f2 29%, #d911f2 66%, #ffa079 100%);
  background: -webkit-linear-gradient(45deg, #02e1ba 0%,#26c9f2 29%,#d911f2 66%,#ffa079 100%);
  background: linear-gradient(45deg, #02e1ba 0%,#26c9f2 29%,#d911f2 66%,#ffa079 100%);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  overflow: hidden;
  position: relative; 
  }
  
  .waiting-room::before, 
  .waiting-room::after {
  content: "";
  width: 70vmax;
  height: 70vmax;
  position: absolute;
  background: rgba(255, 255, 255, 0.07);
  left: -20vmin;
  top: -20vmin;
  animation: morph 15s linear infinite alternate, spin 20s linear infinite;
  z-index: 1;
  will-change: border-radius, transform;
  transform-origin: 55% 55%;
  pointer-events: none; 
  }
  
  .waiting-room::after {
    width: 70vmin;
    height: 70vmin;
    left: auto;
    right: -10vmin;
    top: auto;
    bottom: 0;
    animation: morph 10s linear infinite alternate, spin 26s linear infinite reverse;
    transform-origin: 20% 20%; 
  }
  
  @-webkit-keyframes Gradient {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
  }
  
  @-moz-keyframes Gradient {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
  }
  
  @keyframes Gradient {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
  }
  
  @keyframes morph {
  0% {
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%; }
  100% {
    border-radius: 40% 60%; } 
  }
  
  @keyframes spin {
  to {
    transform: rotate(1turn); 
  } 
  }
  .st0{display:none;}
  .st1{display:inline;}
  .st2{opacity:0.29;}
  .st3{fill:#FFFFFF;}
  .st4{clip-path:url(#SVGID_2_);fill:#FFFFFF;}
  .st5{clip-path:url(#SVGID_4_);}
  .st6{clip-path:url(#SVGID_6_);}
  .st7{clip-path:url(#SVGID_8_);}
  .st8{clip-path:url(#SVGID_10_);}
  .st9{fill:none;}
  .st10{clip-path:url(#SVGID_12_);}
  .st11{opacity:0.7;}
  .st12{clip-path:url(#SVGID_14_);}
  .st13{opacity:0.2;}
  .st14{clip-path:url(#SVGID_16_);}
  .st15{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
/* body {
background: -moz-linear-gradient(45deg, #02e1ba 0%, #26c9f2 29%, #d911f2 66%, #ffa079 100%);
background: -webkit-linear-gradient(45deg, #02e1ba 0%,#26c9f2 29%,#d911f2 66%,#ffa079 100%);
background: linear-gradient(45deg, #02e1ba 0%,#26c9f2 29%,#d911f2 66%,#ffa079 100%);
background-size: 400% 400%;
-webkit-animation: Gradient 15s ease infinite;
-moz-animation: Gradient 15s ease infinite;
animation: Gradient 15s ease infinite;
min-height: calc(100vh - 2rem);
display: flex;
flex-direction: column;
align-items: stretch;
justify-content: space-evenly;
overflow: hidden;
position: relative; 
}

body::before, 
body::after {
content: "";
width: 70vmax;
height: 70vmax;
position: absolute;
background: rgba(255, 255, 255, 0.07);
left: -20vmin;
top: -20vmin;
animation: morph 15s linear infinite alternate, spin 20s linear infinite;
z-index: 1;
will-change: border-radius, transform;
transform-origin: 55% 55%;
pointer-events: none; 
}

body::after {
  width: 70vmin;
  height: 70vmin;
  left: auto;
  right: -10vmin;
  top: auto;
  bottom: 0;
  animation: morph 10s linear infinite alternate, spin 26s linear infinite reverse;
  transform-origin: 20% 20%; 
}

@-webkit-keyframes Gradient {
0% {
  background-position: 0 50%
}
50% {
  background-position: 100% 50%
}
100% {
  background-position: 0 50%
}
}

@-moz-keyframes Gradient {
0% {
  background-position: 0 50%
}
50% {
  background-position: 100% 50%
}
100% {
  background-position: 0 50%
}
}

@keyframes Gradient {
0% {
  background-position: 0 50%
}
50% {
  background-position: 100% 50%
}
100% {
  background-position: 0 50%
}
}

@keyframes morph {
0% {
  border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%; }
100% {
  border-radius: 40% 60%; } 
}

@keyframes spin {
to {
  transform: rotate(1turn); 
} 
}
.st0{display:none;}
.st1{display:inline;}
.st2{opacity:0.29;}
.st3{fill:#FFFFFF;}
.st4{clip-path:url(#SVGID_2_);fill:#FFFFFF;}
.st5{clip-path:url(#SVGID_4_);}
.st6{clip-path:url(#SVGID_6_);}
.st7{clip-path:url(#SVGID_8_);}
.st8{clip-path:url(#SVGID_10_);}
.st9{fill:none;}
.st10{clip-path:url(#SVGID_12_);}
.st11{opacity:0.7;}
.st12{clip-path:url(#SVGID_14_);}
.st13{opacity:0.2;}
.st14{clip-path:url(#SVGID_16_);}
.st15{opacity:0.3;fill:#FFFFFF;enable-background:new    ;} */